<template>

	<dashboard-card :title="$t('dashboard.waitingForSign')" :data="data" color="#775DD0">
		<template>
			<edit-2-icon size="1.5x" class="custom-class" style="stroke: #775DD0"></edit-2-icon>
		</template>
	</dashboard-card>
</template>

<script>
import { Edit2Icon } from 'vue-feather-icons'
import DashboardCard from "@/views/cards/dashboardCard";
export default {
  props:{
    data:{
      type:Object
    }
  },
  components: {
    DashboardCard, Edit2Icon
  },
}
</script>
