<template>

	<dashboard-card :title="$t('dashboard.continueToEdit')" :data="data" color="#ff9f43">
		<template>
			<edit-icon size="1.5x" class="custom-class" style="stroke: #ff9f43"></edit-icon>
		</template>
	</dashboard-card>
</template>

<script>
import DashboardCard from "@/views/cards/dashboardCard";
import {EditIcon} from 'vue-feather-icons'

export default {
	props: {
		data: {
			type: Object
		}
	},
	components: {
		DashboardCard, EditIcon
	}
}
</script>
