<template>
	<b-card class="statistic-card" :style="{borderColor: color ? color : '#000'}">
		<router-link :to="to">
			<div class="statistic-card-header">
				<span>{{ title }}</span>
			</div>
			<div class="icon" :style="{borderColor: color ? color : '#000'}">
				<slot/>
			</div>
			<div class="truncate">
				<h2>
					{{ summary === undefined ? 0 : summary.count }}
				</h2>
			</div>
		</router-link>
	</b-card>
</template>

<script>
import {BCard} from "bootstrap-vue";

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		summary: {
			required: true,
		},
		color: {
			type: String
		},
		to: {
			type: Object,
			required: true
		}
	},
	components :{
		BCard
	}
}
</script>

<style lang="scss" scoped>
	.statistic-card {
		border-radius: 20px;
		border: 1px solid;
		text-align: center;
		padding: 30px;
		box-shadow: 0 7px 15px 0 rgba(0,0,0,0.05);
		.card-body {
			padding: 0;
		}
		.statistic-card-header {
			font-size: 15px;
			color: #000;
		}
		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 55px;
			height: 55px;
			border: 1px solid;
			border-radius: 50%;
			margin: 20px 0;
		}
		.truncate {
			h2 {
				font-weight: 900;
				font-size: 54px;
				margin: 0;
			}
		}
	}
</style>