<template>
	<statistic-card :title="$t('dashboard.createdDocumentCount')" :to="{name:'my-documents'}" :summary="summary[0]" color="#FF9E43">
		<template>
			<feather-icon size="21" icon="FileTextIcon" style="stroke: #FF9E43"/>
		</template>
	</statistic-card>
</template>

<script>
import StatisticCard from "@/views/cards/statisticCard";
import {mapState} from "vuex";

export default {
  components: {
    StatisticCard
  },
  computed: {
    ...mapState({
      summary: state => state.dashboard.summary
    })
  }
}
</script>
