<template>
	<statistic-card :title="$t('dashboard.sharedWithMe')" :to="{name:'shared-documents'}" :summary="summary[1]" color="#00E396">
		<template>
			<feather-icon size="21" icon="UsersIcon" style="stroke: #00E396"/>
		</template>
	</statistic-card>
</template>
<script>
import StatisticCard from "@/views/cards/statisticCard";
import {mapState} from "vuex";

export default {
  components: {
		StatisticCard
  },
  computed: {
    ...mapState({
      summary: state => state.dashboard.summary
    })
  },
}
</script>
