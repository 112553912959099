<template>
	<dashboard-card :title="$t('dashboard.waitingForApprove')" :data="data" color="#00E396">
		<template>
			<check-square-icon size="1.5x" class="custom-class" style="stroke: #00E396"></check-square-icon>
		</template>
	</dashboard-card>
</template>

<script>
import DashboardCard from "@/views/cards/dashboardCard";
import {CheckSquareIcon} from 'vue-feather-icons'

export default {
	components: {
		DashboardCard, CheckSquareIcon
	},
	props: {
    data: {
      type: Object
    }
  }
}
</script>