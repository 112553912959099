<template>
	<div class="card-item">
		<h6 class="my-2 text-secondary">
			{{ title }}
		</h6>
		<b-card class="dashboard-card" :style="{backgroundColor: color ? color : '#00E396'}">
		<div class="dashboard-card-header">
			<div class="icon">
				<slot/>
			</div>
			<div class="title">
				<span class="date">{{ getFormattedDate(data.updatedAt) }}</span>
				<router-link :to="{ name: 'editor', params: { id: data.documentId }}">
					<h5>
						{{ data.documentName }}
					</h5>
				</router-link>
			</div>
		</div>
		<div class="dashboard-card-footer">
			<div class="edited-by">
				<h6>{{ $t('dashboard.editedBy') }}</h6>
				<div class="font-weight-bold">{{ data.modifiedBy }}</div>
			</div>
			<div class="card-link">
				<router-link :to="{ name: 'editor', params: { id: data.documentId }}">
					<feather-icon icon="ArrowRightCircleIcon" size="21"/>
				</router-link>
			</div>
		</div>
	</b-card>
	</div>
</template>

<script>
import {BCard} from "bootstrap-vue";
import DateUtil from "@/services/DateUtil";
import Ripple from "vue-ripple-directive";

export default {
	name: 'dashboardCard',
	props: {
		title: {
			type: String,
		},
		color: {
			type: String,
		},
		data: {
			required: true
		}
	},
	components: {
		BCard
	},
	directives: {
		Ripple
	},
	methods: {
		getFormattedDate(date) {
			return DateUtil.getFormattedTime(date);
		},
	}
}
</script>

<style lang="scss" scoped>
.dashboard-card {
	background: white;
	border-radius: 20px;
	border: none !important;
	box-shadow: 0 7px 15px 0 rgba(black, .05) !important;

	.dashboard-card-header {
		display: flex;
		align-items: center;
		padding: 20px 0;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fafafa;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			margin-right: 15px;

			svg {
				stroke: #03c388;
			}
		}

		.title {
			.date {
				color: rgba(white, .7);
				font-size: 12px;
			}

			h5 {
				font-size: 17px;
				font-weight: bold;
				color: white;
			}
		}
	}

	.dashboard-card-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid rgba(#fff, .15);
		padding-top: 20px;

		.edited-by {
			width: 50%;
			color: white;

			h6 {
				color: white;
			}
		}

		.card-link {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: white;

			svg {
				stroke: white;
			}
		}
	}
}
</style>