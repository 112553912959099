<template>
	<b-card class="chart-card">
		<div class="chart-title">
			<router-link :to="{name:'shared-documents'}">
				<h3>{{ $t('dashboard.byTypes') }}</h3>
			</router-link>
		</div>
		<div class="chart" v-if="data.series || data.frequencies">
			<apexchart id="chart" ref="donut" type="donut" :options="options" :series="data.series">
			</apexchart>
			<div class="chart-content">
				<h4>
					{{ $t('dashboard.mostUsedOnes') }}
				</h4>
				<ul>
					<li v-for="(freq,index) in data.frequencies" :key="index">{{ freq }}</li>
				</ul>
			</div>
		</div>
		<div v-else>
			{{ $t('dashboard.documentNotFound') }}
		</div>
	</b-card>
</template>

<script>
import {BCard} from 'bootstrap-vue'
import i18n from "@/libs/i18n";
import {PEventBus} from "@/services/PEventBus";

export default {
	components: {
		BCard,
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {
					series: [0]
				}
			},
		},
	},
	mounted() {
		PEventBus.$on('locale-updated', () => {
			this.$refs.donut.refresh()
			this.$refs.donut.updateOptions(this.options);
		})
		this.$refs.donut.refresh()
	},
	beforeDestroy() {
		PEventBus.$off('locale-updated')
	},
	watch: {
		data: {
			handler(newVal, oldVal) {
				this.options.labels = newVal.labels
				this.$refs.donut.updateOptions(this.options);
			}
		}
	},
	data() {
		return {
			options: {
				chart: {
					width: "100%",
					height: "100%",
					type: "donut"
				},
				plotOptions: {
					pie: {
						horizontal: true,
						donut: {
							size: '70%',
							labels: {
								show: true,
								name: {
									show: true,
									offsetY: 15,
									fontSize: '10px',
									fontFamily: 'Rubik',
									color: '#3d3f37',
									formatter: () => {
										return i18n.t("dashboard.count")
									}
								},
								value: {
									show: true,
									label: '',
									fontSize: '16px',
									fontFamily: 'Helvetica, Arial, sans-serif',
									color: '#3d3f37',
									offsetY: -20,
								},
								total: {
									show: true,
									offsetY: -15,
									fontSize: '5px',
									color: '#3d3f37',
								}
							}
						}
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: 1,
					colors: ["#fff"]
				},
				legend: {show: false},
				responsive: [
					{
						breakpoint: 1000,
						options: {
							plotOptions: {
								donut: {
									horizontal: false
								}
							},
							legend: {show: false},
						}
					}
				]
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label,
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
	font-size: 1.0rem;
}

.chart-card {
	border-radius: 20px;
	text-align: center;
	.chart-title {
		a {
			h3 {
				font-size: 15px;
				font-weight: bold;
				color: #000;
			}
		}
	}
	.chart {
		#chart {

		}
		.chart-content {
			text-align: center;
			h4 {
				font-size: 15px;
				margin-bottom: 15px;
			}
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				li {
					font-size: 12px;
					margin: 5px 0;
				}
			}
		}
	}
}

#chart {
	max-width: 760px;
}
</style>
