<template>
	<section id="dashboard-ecommerce">
		<b-row>

			<b-col xl="12" md="12">
				<b-row>
					<b-col xl="4" md="6" sm="12" v-show="getDraftDocument[0] != null">
						<ecommerce-design-card :data="getDraftDocument[0]"/>
					</b-col>
					<b-col xl="4" md="6" sm="12" v-show="getWaitingToApproveDocument[0] != null">
						<ecommerce-assent-card :data="getWaitingToApproveDocument[0]"/>
					</b-col>
					<b-col xl="4" md="6" sm="12" v-show="getWaitToSignDocument[0] != null">
						<ecommerce-change-card :data="getWaitToSignDocument[0]"/>
					</b-col>
				</b-row>
			</b-col>

			<b-col xl="9" md="6">
				<b-row>
					<b-col xl="12" md="12">
						<h6 class="text-secondary mb-2">
							{{ $t('dashboard.ourStats') }}
						</h6>
					</b-col>
					<b-col xl="6" md="12">
						<ecommerce-loading-documents/>
					</b-col>
					<b-col xl="6" md="12">
						<ecommerce-sharing-documents/>
					</b-col>
					<b-col xl="12" md="12">
						<h6 class="mb-2 text-secondary">
							{{ $t('dashboard.nearlyEndingDocuments') }}
						</h6>
						<b-row v-if="remindersDocuments.length !== 0">
							<b-col v-for="item in getRemindersDocument" :key="item.documentName" xl="12" md="6">
								<ecommerce-expire-card :data="item"/>
							</b-col>
						</b-row>
						<ecommerce-error-expire-card v-else/>
					</b-col>
				</b-row>
			</b-col>

			<b-col xl="3" md="6">
				<h6 class="text-secondary mb-2">
					{{ $t('dashboard.ourStats') }}
				</h6>
				<ecommerce-type-documents :data="typingDocuments"/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import {BCol, BRow} from 'bootstrap-vue'

import {getUserData} from '@/auth/utils'
import EcommerceLoadingDocuments from "@/views/dashboard/ecommerce/EcommerceLoadingDocuments";
import EcommerceSharingDocuments from "@/views/dashboard/ecommerce/EcommerceSharingDocuments";
import EcommerceTypeDocuments from "@/views/dashboard/ecommerce/EcommerceTypeDocuments";
import EcommerceDesignCard from "@/views/dashboard/ecommerce/EcommerceDesignCard";
import EcommerceExpireCard from "@/views/dashboard/ecommerce/EcommerceExpireCard";
import EcommerceAssentCard from "@/views/dashboard/ecommerce/EcommerceAssentCard";
import EcommerceChangeCard from "@/views/dashboard/ecommerce/EcommerceChangeCard";
import EcommerceErrorExpireCard from "@/views/dashboard/ecommerce/EcommerceErrorExpireCard";
import {mapState} from 'vuex'

export default {
	components: {
		BRow,
		BCol,
		EcommerceLoadingDocuments,
		EcommerceSharingDocuments,
		EcommerceErrorExpireCard,
		EcommerceAssentCard,
		EcommerceTypeDocuments,
		EcommerceExpireCard,
		EcommerceDesignCard,
		EcommerceChangeCard,
	},
	data() {
		return {}
	},
	created() {
		this.fetchSummary();
		this.getSummaryDocumentsCard();
		this.getTypingDocuments();
		this.getReminderDocumentsCard();
	},
	methods: {
		fetchSummary() {
			this.$store.dispatch('dashboard/getSummary')
		},
		getSummaryDocumentsCard() {
			this.$store.dispatch('dashboard/getSummaryDocumentsCard')
		},
		getTypingDocuments() {
			this.$store.dispatch('dashboard/getTypingDocuments')
		},
		getReminderDocumentsCard() {
			this.$store.dispatch('dashboard/getReminderDocumentsCard')
		},
		topType() {
			let a = this.data.series.length;
			for (let i = 0; i < a; i++) {
				let topSeries = 0;
				let topSeries2 = 0;
				let topSeries3 = 0;
				if (this.data.series[i] > topSeries) {
					topSeries3 = topSeries2;
					topSeries2 = topSeries;
					topSeries = this.data.series[i];
					break;
				} else if (this.data.series[i] > topSeries2) {
					topSeries3 = topSeries2;
					topSeries2 = this.data.series[i];
					break;
				} else if (this.data.series[i] > topSeries3) {
					topSeries3 = this.data.series[i];
					break;
				}
				console.log(topSeries, topSeries2, topSeries3)
			}
		}

	},
	computed: {
		...mapState({
			summary: state => state.dashboard.summary,
			loadingDocuments: state => state.dashboard.loadingDocuments,
			sharingDocuments: state => state.dashboard.sharingDocuments,
			typingDocuments: state => state.dashboard.typingDocuments,
			documentsCurrent: state => state.dashboard.currentDocument,
			remindersDocuments: state => state.dashboard.reminderDocuments
		}),
		getWaitToSignDocument: function () {
			return this.documentsCurrent.filter(el => {
				return el.documentStatus.name === "WAITING_TO_SIGN"
			})
		},
		getDraftDocument: function () {
			return this.documentsCurrent.filter(el => {
				return el.documentStatus.name === "DRAFT"
			})
		},
		getWaitingToApproveDocument: function () {
			return this.documentsCurrent.filter(el => {
				return el.documentStatus.name === "WAITING_TO_APPROVE"
			})
		},
		getLoadingDocument: function () {
			return this.summary.filter(el => {
				return el.type === "DOCUMENT_COUNT_CARD"
			})
		},
		getRemindersDocument: function () {
			return this.remindersDocuments.filter((el, index) => {
				if (index < 3) {
					return el;
				}
			})
		}


	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
