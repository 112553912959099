<template>
	<b-card class="card-alert">
		<b-card-text class="my-1 help-text text-muted">
			<alert-circle-icon size="21"/>
			{{ $t('dashboard.notExistsNearlyEndingDocuments') }}
		</b-card-text>
	</b-card>
</template>

<script>
import {AlertCircleIcon} from 'vue-feather-icons'
import {BCard, BCardText} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCard, BCardText, AlertCircleIcon
	},
	directives: {
		Ripple,
	},
}
</script>

<style>
.card-alert {
	border-radius: 20px;
}
.help-text {
	font-size: 13px;
	text-align: center;
}
</style>