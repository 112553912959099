<template>
  <section id="card-text-alignment" class="w-100">

    <b-row>

      <!-- center align -->
      <b-col
          class="w-100"
          md="6"
          lg="12"
      >

        <b-card
            :title="data.reminderTitle"
            class="text-center w-100"
        >
          <div class="progress my-1">
            <div class="progress-bar bg-danger" role="progressbar" :style="{'width':data.timeCompleteness+'%'}"
                 :aria-valuenow="data.timeCompleteness" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <b-card-text>
            {{ $t('dashboard.endDate') }} <span style="color: #3b4cbc;font-weight: bold">{{ getReminderFinishDate(data.endDate) }}</span>
          </b-card-text>
          <b-card-footer class="d-flex justify-content-end p-0">
           <b-link class="nav-link p-0 pt-1" :to="{name:'editor', params: { id: data.documentId }}">
             <arrow-right-circle-icon size="1.5x" class="custom-class text-danger"></arrow-right-circle-icon>
           </b-link>
          </b-card-footer>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {BCard, BCardFooter, BCardText, BCol, BRow,BLink} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ArrowRightCircleIcon} from 'vue-feather-icons'
import DateUtil from "@/services/DateUtil";

export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {
    BRow, BCol, BCard, BCardText, BCardFooter, ArrowRightCircleIcon,BLink
  },
  directives: {
    Ripple,
  },
  methods: {
    getReminderFinishDate(date) {
      let parts = this.getFormattedDate(date).split(" ")

      return parts[0].concat(" ").concat(parts[1]).concat(" ").concat(parts[2])
    },
    getFormattedDate(date) {
      return DateUtil.getFormattedTime(date);
    },
  }
}
</script>
